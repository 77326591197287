<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <Helper v-model="reportHelper" header="Buscar Reporte" :headers="headers" :rows="entities" @selected="selectEntity" />

    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Generador de Reportes</h2>
        <BasicFormToolbar @search="reportHelper.visible = true" @new="openNew" @save="save" @refresh="refresh" :actions="['new', 'refresh', 'save', 'search']" />
        <div class="grid formgrid p-fluid">
          <div class="col-12">
            <TabView ref="tabview1">
                    <TabPanel header="Consulta">
                      <Fieldset legend="Informacion">
                        <div class="grid formgrid p-fluid">
                <div class="col-12 field">
                  <label>Perfiles</label>
                  <MultiSelect v-model="entity.profiles" :filter="true" :options="profiles" optionValue="id" optionLabel="name" display="chip" />
                </div>
                  <FormDropdown :wrapperClass="'col-4 field'" v-model="entity.id_sql_query_type" label="Tipo" :options="types" optionLabel="name" :optionValue="'id'" />
                  <FormInputText :wrapperClass="'col-8 field'" v-model="entity.title" :label="'Titulo'" />

                  <p>Para realizar una consulta puedes utilizar lo siguiente: [Label, INPUT], [Label, DATE], [Label, DATETIME], [Label, ENTITYNAME|VALUE|LABEL, DROPDOWN], [Label, BOOLEAN], 
                    [SessionName, SESSION]  *Valores disponibles: COMPANY, BRANCH, USER*
                  </p>
                  <p>NO se pueden usar funciones de UPDATE, DELETE o INSERT</p>
                  <Textarea v-model="entity.query" :autoResize="true" rows="20" cols="30" />
                      </div>
                      </Fieldset>
                    </TabPanel>
                    <TabPanel header="Grafica">
                      <Fieldset legend="Informacion">
                        <div class="grid formgrid p-fluid">
                          <FormDropdown v-model="entity.chart_type" :wrapperClass="'col-12 field'" :label="'Tipo'" :options="graphs" :optionLabel="'text'" :optionValue="'id'" />
                          <FormInputText v-model="entity.chart_label_column" :wrapperClass="'col-6 field'" label="Columna de Etiqueta" />
                          <FormInputText v-model="entity.chart_data_column" :wrapperClass="'col-6 field'" label="Columna de Informacion" />
                          <FormInputNumber :showStepper="true" v-model="entity.chart_datasets" :wrapperClass="'col-2 field'" label="# de Datasets" :min="1" :max="5" :minFractionDigits="0" :maxFractionDigits="0" />
                          <FormInputText v-for="chart_dataset in entity.chart_datasets" :key="'dataset_' + chart_dataset" v-model="entity.chart_datasets_name[chart_dataset - 1]" :wrapperClass="'col-2 field'" :label="'Nombre #'+ chart_dataset"/>
                        </div>
                      </Fieldset>
                      </TabPanel>
                    </TabView>
          </div>
        </div>
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ERPProfile } from "../../../models/seguridad/ERPProfile";
import { SQLQuery } from "../../../models/general/SQLQuery";
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';

import Helper from '../../../components/general/HelperDialog.vue';
import { SQLQueryType } from '../../../models/general/SQLQueryType';

export default {
  mixins: [Session],
  data() {
    return {
      reportHelper:  {
          visible: false,
      },
      entity: null,
      entities: [],
      types: [],
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      profiles: [],
      rules: [
        new Rule({ name: "title" }),
        new Rule({ name: "query"})
      ],
      graphs: [
        {id: '' , text: 'SIN GRAFICA'},
        {id: 'PIE' , text: 'PASTEL'},
        {id: 'DOUGHNUT' , text: 'DONA'},
        {id: 'BAR' , text: 'BARRAS'},
        {id: 'LINE' , text: 'LINEAS'},
        {id: 'POLAR' , text: 'POLAR'},
        {id: 'RADAR' , text: 'RADAR'}
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null,
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [
        new HeaderGrid("Titulo", "title")
      ],
      loading: false,
    };
  },
  components: {FormInputNumber, FormDropdown, FormInputText, Helper,Loader, BasicFormToolbar, ConfirmDeleteDialog },
  created() {
    console.log(this.session);
    this.entity = new SQLQuery(this.session);
    this.uploadURL =
      this.$config.api_route +
      "General/SQLQuery/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
  },
  watch: {
    'entity.chart_datasets'(newValue, oldValue) {
      let newStrings = newValue - oldValue;
      if (this.entity.chart_datasets_name.length != newValue) {
        if (newStrings >= 0)
        {
          for (let i = 0; i < newStrings; i++) {
            this.entity.chart_datasets_name.push('');
          }
        }else {
          for (let i = 0; i < Math.abs(newStrings); i++) {
            this.entity.chart_datasets_name.pop();
          }
        }
      }
    }
  },
  methods: {
    openNew() {
      this.entity = new SQLQuery(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        for (let i = 0; i < this.entity.chart_datasets_name.length; i++) {
          if(this.entity.chart_datasets_name[i] == "")
            throw "[Grafica] Favor de agregar el nombre del dataset #" + (i + 1);
        }


        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new SQLQuery(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    selectEntity(entity) {
      fillObject(this.entity, entity);
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new SQLQuery(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
        this.types = await new SQLQueryType(this.session).all();
        this.profiles = await new ERPProfile(this.session).all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
